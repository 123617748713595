import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-hot-toast';
import CSCApi from '../services/CSCApi';

const emptyState = {
  amount: 0
}

export default function AddPaymentExpense({open, close, reload, expense}) {
  const [expenseInfo, setExpenseInfo] = useState(emptyState)
  const [loading, setLoading] = useState(false);
  const amountPayed = expense.expense_payments.reduce((acc, payment) => acc + Number(payment.amount), 0);
  const handleAddPaymentExpense = async () => {
    if(loading) return;
    if(Number(expenseInfo.amount_paid) === 0) return toast.error('La cantidad pagada no puede ser 0');
    if((Number(expenseInfo.amount) + amountPayed) > Number(expense.amount)) return toast.error('La cantidad pagada no puede ser mayor  a la deuda');
    setLoading(true);
    try{
      toast.loading('Creando gasto...', {id: 'addPaymentExpense'});
      const {message} = await CSCApi.addPaymentExpense({
        amount: expenseInfo.amount,
        expense_id: expense.id
      });
      toast.success(message, {id: 'addPaymentExpense'});
      handleClose();
      reload();
    } catch(e){
      toast.error(e, {id: 'addPaymentExpense'});
    }
    setLoading(false);
  }

  const handleClose = () => {
    setExpenseInfo(emptyState);
    close()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex w-full sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base pb-2 font-semibold leading-6 text-gray-900">
                      Añadir pago a gasto
                    </Dialog.Title>
                    <div>
                        <p className='text-sm font-bold'>Cantidad a pagar</p>
                        <input value={expenseInfo.amount} onChange={(e) => setExpenseInfo({...expenseInfo, amount: e.target.value})}
                          type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Total Pagado" />
                        <p className='text-xs'> Restante: {Number(expense.amount) - amountPayed }</p>
                      </div>
                  </div> 
                </div>
                <div className="flex justify-center items-center pt-8 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => handleAddPaymentExpense()}
                  >
                    Pagar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleClose}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}