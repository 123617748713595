
import {
  CogIcon,
  HomeIcon,
  FireIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

/* eslint-disable import/no-anonymous-default-export */
export default [
  { name: 'General', href: '/dashboard', icon: HomeIcon},
  { name: 'Socios', href: '/members', icon: UserGroupIcon},
  { name: 'Dispensario', href: '/dispensary', icon: BuildingStorefrontIcon},
  { name: 'Productos', href: '/products', icon: FireIcon},
  { name: 'Administracion', href: '/administration', icon: CogIcon},
]