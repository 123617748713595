module.exports = [{
  name: "Flores",
  color: "bg-green-500 focus:bg-green-700",
}, {
  name: "Dry Sift",
  color: "bg-blue-500 focus:bg-blue-700",
},{
  name:  "Extractos",
  color: "bg-yellow-500 focus:bg-yellow-700",
}, {
  name: "CBD",
  color: "bg-pink-500 focus:bg-pink-700",
}, {
  name: "Otros",
  color: "bg-purple-500 focus:bg-purple-800",
}]