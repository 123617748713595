import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../context";
import productCategories from "../../../config/productCategories";
import { toast } from "react-hot-toast";
import CSCApi from "../../../services/CSCApi";
import { XCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function POS({memberID, setSelectedMember}){
  const [member, setMember] = useState({});
  const { setDispensaryMode } = useContext(GlobalContext);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadProducts = async () => {
    if(loading) return;
    setLoading(true);
    try{
      const {products} = await CSCApi.getProducts();
      setProducts(products);
      setFilteredProducts(products);
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  const reloadUser = async () => {
    try{
      const {members: newMember} = await CSCApi.getMember(memberID);
      setMember(newMember);
    } catch(e){
      toast.error(e);
    }
  }


  useEffect(() => {
    setDispensaryMode({
      active: true,
      action: () => {
        setSelectedMember(() => {});
      }
    });
    loadProducts();
    reloadUser();
    return () => {
      setDispensaryMode({
        active: false,
        action: () => {}
      });
    }
  }, [])
  

  const handleFilterCategory = (category) => {
    if(category.name === "Todos"){
      setFilteredProducts(products);
    } else {
      setFilteredProducts(products.filter((product) => product.category === category.name));
    }
  }

  const addProduct = (product) => {
    const oldProduct = selectedProducts.find((p) => p.id === product.id);
    if(oldProduct) return toast.error("Este producto ya fue agregado", {id: "product"});
    setSelectedProducts([...selectedProducts, {...product, quantity: 1}])
  }

  const removeProduct = (product) => {
    const productIndex = selectedProducts.findIndex((p) => p.id === product.id);
    if(productIndex === -1) return toast.error("Este producto no existe", {id: "product"});
    const newProducts = selectedProducts.filter((p) => p.id !== product.id);
    setSelectedProducts(newProducts);
  }

  const createDispensation = async () => {
    if(selectedProducts.length === 0) return toast.error("No hay productos seleccionados", {id: "product"});
    const dispensation = {
      member_id: member.id,
      products: selectedProducts.map((product) => ({
        product: product.id,
        quantity: product.quantity,
        cost: product.cost
      }))
    }
    try{
      const {message} = await CSCApi.createDispensation(dispensation);
      toast.success(message, {id: "product"});
      setSelectedProducts([]);
      setSelectedMember(null);
    } catch(e){
      toast.error(e, {id: "product"});
    }
  }

  const handleChangeQuantity = (product, e) => {
    const initialCost = products.find((p) => p.id === product.id).cost;
    const newProducts = selectedProducts.map((p) => {
      if(p.id === product.id){
        return {
          ...p, 
          quantity: e.target.value,
          cost: (Number(initialCost) * Number(e.target.value)).toFixed(2)
        }
      }
      return p;
    });
    setSelectedProducts(newProducts);
  }

  const handleChangeCosts = (product, e) => {
    const newProducts = selectedProducts.map((p) => {
      if(p.id === product.id){
        return {...p, cost: e.target.value}
      }
      return p;
    });
    setSelectedProducts(newProducts);
  }

  return (<div className="w-full h-full bg-gray-200  grid md:grid-cols-8 text-white font-bold overflow-hidden transition-all">
    <div className="md:col-span-6 col-span-8 md:pb-0 pb-5 overflow-y-auto pt-4">
      <div className="grid md:grid-cols-6 grid-cols-3 gap-4 px-3">
        {[{
            name: "Todos",
            color: "bg-gray-500 focus:bg-gray-700",
          }, ...productCategories].map((category) => (<button key={category.name} onClick={() => handleFilterCategory(category)}
        className={`${category.color} flex items-center justify-center py-3 border rounded-xl`}>
          {category.name}
        </button>))}
      </div>
      <div className="px-3 pt-4">
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {filteredProducts.map((product) => (
            <li key={product.id} className="col-span-1 flex rounded-md shadow-sm cursor-pointer" onClick={() => addProduct(product)}>
              <div
                className={classNames(
                  productCategories.find((category) => category.name === product.category).color,
                  'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                )}
              >
                {(product.name.charAt(0) + product.name.charAt(1)).toUpperCase()}
              </div>
                <div className="flex flex-1 items-start justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                  <div className="">
                    <div className="flex-1 truncate px-4 py-4 text-sm text-gray-900">
                      {product.name}
                    </div>
                    <div className="flex-1 flex items-center truncate px-4 pb-4 text-sm text-gray-900">
                      <div className="p-2 mr-3 bg-indigo-600 h-6 w-6 rounded-full flex items-center justify-center text-white">
                        {product.cost}
                      </div>
                      Stock: <span className="font-medium ml-1">{product.stock}</span>
                    </div>
                  </div>
                    <div className="font-medium px-4 py-4 text-sm text-gray-900">
                      
                    
                    </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <div className="md:col-span-2 col-span-8 w-full bg-gradient-to-b from-white to-gray-200 border-l border-gray-200 shadow-lg md:pt-0 overflow-y-auto flex flex-col">
      <div className="flex justify-center p-4 text-white text-lg bg-gray-900 border-b border-gray-700">
        <h2> Socio: <span className="font-light">{member.name}</span> </h2>
      </div>
      <div className="flex-1 divide-y divide-green-600">
        {selectedProducts.map((product) => (<div key={product.id} className="p-4 hover:bg-gray-200">
          <div className="text-gray-700 pl-2">
            <div className="flex justify-between items-center">
              <div className="">{product.name}</div>
              <div>
                <button onClick={() => removeProduct(product)}> <XCircleIcon className="h-6 text-red-600"/> </button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="text-gray-600 pt-2 text-sm font-normal w-ful">
                Cantidad <input type="number" className="text-gray-600 border w-full border-green-400 rounded-lg px-2" value={product.quantity} onChange={(e) => handleChangeQuantity(product, e)}/>
              </div>
              <div className="text-gray-600 pt-2 text-sm font-normal w-full pb-4">
                Coste <input type="number" className="text-gray-800 border w-full border-green-400 rounded-lg px-2" value={product.cost} onChange={(e) => handleChangeCosts(product, e)}/>
              </div>
            </div>
          </div>
        </div>))}
      </div>
      <div className="grid grid-cols-2 text-gray-800 pb-4">
        <div className="text-center font-bold">Saldo: <span className="font-medium">{member.balance}</span></div>
        <div className="text-center font-bold">Total: <span className="font-medium">{selectedProducts.reduce((acc, product) => acc + Number(product.cost), 0).toFixed(2)}</span></div>
      </div>
      <button onClick={createDispensation}
      className="w-full bg-gradient-to-r from-green-700 via-green-500 to-green-700 hover:bg-gradient-to-r hover:from-green-700 hover:via-green-700 hover:to-green-700 py-4 flex justify-center items-center">
        Dispensar 
      </button>
    </div>
  </div>)
}