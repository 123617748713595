import MainLayout from "../../../layout/MainLayout";
import Datepicker from "react-tailwindcss-datepicker";
import React, {useEffect, useState} from "react";
import {  CircleStackIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import CSCApi from "../../../services/CSCApi";

export default function MostlyDispensedMembers(){
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date() - 1000 * 60 * 60 * 24,
    endDate: new Date()
  });

  const handleChangeDateFilter = (newValue) => {
    console.log("newValue:", newValue);
    setDateFilter(newValue);
  }

  const loadDonations = async () => {
    if(loading) return;
    setLoading(true);
    try{
      const {members} = await CSCApi.getMostlyActiveMembers({
        date_from: new Date(dateFilter.startDate),
        date_to: new Date(dateFilter.endDate)
      });
      setMembers(members);
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadDonations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])

  return(<MainLayout>
    <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex-1 py-4">
          <div className="flex items-center gap-2 text-green-800">
            <UserGroupIcon className="h-8"/>
            <h1 className="font-bold leading-6 text-gray-900 uppercase">Historial de socios más activos</h1>
          </div>
        </div>
      <Datepicker
          inputClassName="border border-gray-300 rounded-md shadow-sm dark:bg-white dark:text-black"
          primaryColor={"green"} 
          value={dateFilter}
          onChange={handleChangeDateFilter}
      />
      <div className="mt-8 flow-root">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0">
                    ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Socio
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Cantidad total
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Donacion total
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Dispensaciones totales
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {members.map((member) => (<tr key={member.member_id} className='hover:bg-gray-300'>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                  <div className="font-medium text-gray-900">{member.member_id}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                    {member.member?.name} {member.member?.last_name}
                  </td>
                  
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                    {member.total_amount} g
                  </td>
                  <th className="px-3 py-3.5 text-left text-sm text-gray-500 font-normal capitalize">
                    {member.total_donation} CSC
                  </th>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                    {member.total_activity} 
                  </td>
                </tr>))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>)
}