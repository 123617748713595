import { useState } from 'react'
import Sidebar from '../components/Sidebar'

import Topbar from '../components/Topbar'


export default function MainLayout({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (<div className="flex h-full">
    <Sidebar mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
    {/* Content area */}
    <div className="flex flex-1 flex-col overflow-hidden">
      <Topbar setMobileMenuOpen={setMobileMenuOpen} />
      {/* Main content */}
      <div className="overflow-hidden h-full w-full">
        <main className="overflow-y-auto w-full h-full">
          {children}
        </main>
      </div>
    </div>
  </div>)
}
