import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { GlobalProvider } from './context';
import './index.css';
import Pages from './pages';
import Preloader from './Preloader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter>
  <GlobalProvider>
    <Toaster />
    <Preloader>
      <Pages />
    </Preloader>
  </GlobalProvider>
</BrowserRouter>);