import MainLayout from "../../layout/MainLayout";
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { UserPlusIcon, UserGroupIcon, FireIcon, BuildingStorefrontIcon, CircleStackIcon, BanknotesIcon, ShoppingBagIcon, BeakerIcon, CalendarIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const administrationTabs = [
  {
    name: 'Calendario',
    description: 'Ver el calendario de eventos',
    icon: CalendarIcon,
    href: '/administration/calendar'
  },
  {
    name: 'Gastos',
    description: 'Gestiona los gastos de la asociación',
    icon: CurrencyDollarIcon,
    href: '/administration/expenses'
  },
  {
    name: 'Historial Cuotas',
    description: 'Ver el historial de cuotas',
    icon: CircleStackIcon,
    href: '/administration/subscriptionHistory'
  },
  {
    name: 'Historial Dispensario',
    description: 'Ver el historial de dispensario',
    icon: BuildingStorefrontIcon,
    href: '/administration/dispensaryHistory'
  },
  {
    name: 'Historial Donaciones',
    description: 'Ver el historial de donaciones',
    icon: BanknotesIcon,
    href: '/administration/donationHistory'
  },
  {
    name: 'Historial Stock',
    description: 'Ver los movimientos de stock',
    icon: ShoppingBagIcon,
    href: '/administration/stockMovements'
  },
  {
    name: 'Dispensaciones por día',
    description: 'Ver las dispensaciones por día',
    icon: BeakerIcon,
    href: '/administration/dispensationsDay'
  },
  {
    name: 'Productos más dispensados',
    description: 'Ver los productos más dispensados',
    icon: FireIcon,
    href: '/administration/mostlyDispensedProducts'
  },
  {
    name: 'Socios más activos',
    description: 'Ver los socios con mayor numero de dispensaciones',
    icon: UserGroupIcon,
    href: '/administration/mostActiveMembers'
  },
  {
    name: 'Socios por dispensación',
    description: 'Ver los socios con la mayor dispensación',
    icon: UserPlusIcon,
    href: '/administration/mostlyDispensedMembers'
  },
]

export default function Administration(){
  return(<MainLayout>
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {administrationTabs.map((adminTab, index) => (
          <li key={index}>
            <Link to={adminTab.href} className="flex items-center hover:bg-gray-50 w-full">
              <div className="px-4 py-4 sm:px-6 mr-1">
                <div className="w-12 h-12 bg-gray-300 rounded-full p-2 flex items-center justify-center">
                  <adminTab.icon className="text-gray-500" aria-hidden="true" />
                </div>
              </div>
              <div className="flex-1 text-start">
                  <h3 className="text-sm font-medium text-gray-900">{adminTab.name}</h3>
                  <p className="text-sm text-gray-500">{adminTab.description}</p>
                </div>
              <div className="px-4 py-3">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </MainLayout>)
}