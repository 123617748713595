import MainLayout from "../../../layout/MainLayout";
import Datepicker from "react-tailwindcss-datepicker";
import React, {useEffect, useState} from "react";
import { UserGroupIcon, TrashIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import CSCApi from "../../../services/CSCApi";
import CreateExpense from "../../../components/CreateExpense";
import AddPaymentExpense from "../../../components/AddPaymentExpense";
import ConfirmModal from "../../../components/ConfirmModal";

export default function Expenses(){
  const [expenses, setExpenses] = useState([]);
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  const [addPaymentExpenseModal, setAddPaymentExpenseModal] = useState({
    open: false,
    expense: null
  });
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    open: false,
    title: 'Eliminar gasto',
    description: '¿Estás seguro de eliminar este gasto?',
    onConfirm: () => {}
  });
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date() - 1000 * 60 * 60 * 24,
    endDate: new Date()
  });

  const loadExpenses = async () => {
    console.log('Loading expenses')
    if(loading) return;
    setLoading(true);
    try{
      const {expenses} = await CSCApi.getExpenses({
        date_from: new Date(dateFilter.startDate),
        date_to: new Date(dateFilter.endDate)
      });
      setExpenses(expenses);
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadExpenses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])

  const reload = () => {
    setDateFilter({
      startDate: new Date() - 1000 * 60 * 60 * 24,
      endDate: new Date()
    })
  }



  const handleChangeDateFilter = (newValue) => setDateFilter(newValue);
  const handleCloseDeleteConfirmModal = () => setDeleteConfirmModal({open: false, title: 'Eliminar gasto', description: '¿Estás seguro de eliminar este gasto?', onConfirm: () => {}});
  const handleOpenDeleteConfirmModal = (expense) => {
    setDeleteConfirmModal({
      open: true,
      title: 'Eliminar gasto',
      description: `¿Estás seguro de eliminar el gasto ${expense.name}?`,
      onConfirm: async () => {
        if(loading) return;
        setLoading(true);
        toast.loading('Eliminando gasto...', {id: 'delete-expense'});
        try{
          await CSCApi.deleteExpense(expense.id);
          toast.success('Gasto eliminado correctamente', {id: 'delete-expense'});
          loadExpenses();
        } catch(e){
          toast.error(e, {id: 'delete-expense'});
        }
      }
    })
  }
  const pendingToPay = expenses.reduce((a, b) => {
    const totalPayed = b.expense_payments.reduce((a, b) => a + Number(b.amount), 0);
    return a + (Number(b.amount) - totalPayed);
  }, 0);
  const totalPayed = expenses.reduce((a, b) => {
    const totalPayed = b.expense_payments.reduce((a, b) => a + Number(b.amount), 0);
    return a + totalPayed;
  }, 0);

  return(<MainLayout>
    <ConfirmModal open={deleteConfirmModal.open} title={deleteConfirmModal.title} description={deleteConfirmModal.description} onConfirm={deleteConfirmModal.onConfirm} close={handleCloseDeleteConfirmModal}/>
    {addPaymentExpenseModal.expense && <AddPaymentExpense reload={reload} open={addPaymentExpenseModal.open} close={() => setAddPaymentExpenseModal(false)} expense={addPaymentExpenseModal.expense}/>}
    <CreateExpense reload={reload} open={createExpenseModal} close={() => setCreateExpenseModal(false)}/>
    <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex-1 md:flex md:justify-between py-4 items-center">
            <div className="flex items-center">
            <UserGroupIcon className="h-8 mr-2"/>
            <h1 className="font-bold leading-6 text-gray-900 uppercase">Gastos</h1>
            </div>
            <div>
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-sm" onClick={() => setCreateExpenseModal(true)}>
                Crear gasto
              </button>
            </div>
        </div>
      <Datepicker
          inputClassName="border border-gray-300 rounded-md shadow-sm dark:bg-white dark:text-black"
          primaryColor={"green"} 
          value={dateFilter}
          onChange={handleChangeDateFilter}
      />
      <div className="py-2 grid grid-cols-2">
        <div className="flex justify-center">
          <div className="flex items-center bg-white p-2 border border-gray-300 rounded-md shadow-sm">
          <p className="text-sm mr-2">Pendiente de pago:</p>
          <p className="text-md font-bold text-red-600">{pendingToPay}</p>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex items-center bg-white p-2 border border-gray-300 rounded-md shadow-sm">
          <p className="text-sm mr-2">Total Pagado:</p>
          <p className="text-md font-bold text-green-600">{totalPayed}</p>
          </div>
        </div>
      </div>
      <div className=" flow-root">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0">
                    ID
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Nombre
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Descripción
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Cantidad
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {expenses.map((expense) => {
                  const totalPayed = expense.expense_payments.reduce((a, b) => a + Number(b.amount), 0);
                  const isPayed = totalPayed >= Number(expense.amount);
                  return (<tr key={expense.id} className='hover:bg-gray-300'>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                    <div div className="font-medium text-gray-900">{expense.id}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                    {expense.name}
                  </td>
                  
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                    {expense.description}
                  </td>
                  <th className={`px-3 py-3.5 text-left text-sm font-normal capitalize ${isPayed ? 'text-green-500' : 'text-red-500'}`}>
                    ({totalPayed} / {expense.amount})
                  </th>
                  <td className="whitespace-nowrap px-3 py-4 text-sm flex justify-end text-gray-500 capitalize">
                    {isPayed || <button onClick={() => setAddPaymentExpenseModal({open: true, expense})}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 rounded-full text-sm mr-3">
                      <CurrencyDollarIcon className="h-4 text-white" />
                    </button>}
                    <button onClick={() => handleOpenDeleteConfirmModal(expense)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded-full text-sm mr-2">
                      <TrashIcon className="h-4 text-white" />
                    </button>
                  </td>
                </tr>)})}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>)
}