import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-hot-toast';
import CSCApi from '../services/CSCApi';

export default function ChangePasswordModal({open, close,  reload}) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = async () => {
    if(password === "") return toast.error("La contraseña actual es requerida");
    if(newPassword === "") return toast.error("La nueva contraseña es requerida");
    if(confirmPassword === "") return toast.error("La confirmación de la contraseña es requerida");
    if(newPassword !== confirmPassword) return toast.error("Las contraseñas no coinciden");
    if(loading) return;
    setLoading(true);
    try{
      const {message} = await CSCApi.changePassword({
        password,
        new_password: newPassword,
      });
      console.log(message);
      toast.success(message);
      close();;
      reload();
    } catch(e){
      console.log(e);
      toast.error(e);
    }
    setLoading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex w-full sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base pb-2 font-semibold leading-6 text-gray-900">
                      Cambiar contraseña
                    </Dialog.Title>
                    <div className='py-4'>
                      <p className="text-sm text-gray-500">
                        Contraseña actual
                      </p>
                      <input value={password} onChange={(e) => setPassword(e.target.value)}
                      type="password" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="" />
                    </div>
                    <div className='pt-4 pb-2'>
                      <p className="text-sm text-gray-500">
                        Nueva Contraseña
                      </p>
                      <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                      type="password" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="" />
                    </div>
                    <div className='pb-4'>
                      <p className="text-sm text-gray-500">
                        Repetir Nueva Contraseña
                      </p>
                      <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="" />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2  mt-5 sm:mt-4 ">
                  <button type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-500 sm:mt-0 sm:w-auto"
                    onClick={() => close()}
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={handleChangePassword}
                  >
                    Cambiar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}