import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../../config/api";
import CSCApi from "../../../services/CSCApi";
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';

export default function MemberSelect({setSelectedMember}){
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadMembers = async () => {
    if(loading) return;
    setLoading(true);
    try{
      const {members} = await CSCApi.getMembers();
      setMembers(members);
      setFilteredMembers(members);
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadMembers();
  }, [])

  const handleFilterMembers = (e) => {
    const { value } = e.target;
    const filtered = members.filter(member => {
      const name = member.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const lastname = member.last_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const nameAndLastname = `${name} ${lastname}`;
      const email = member.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const searchValue = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return (
        name.includes(searchValue) ||
        email.includes(searchValue) ||
        lastname.includes(searchValue) ||
        nameAndLastname.includes(searchValue) ||
        (member.member_number === Number(value)) ||
        member.rfid === value
      );
    });
    if(filtered.length === 1) {
      if(filtered[0].rfid === value) return setSelectedMember(filtered[0]);
    }
    setFilteredMembers(filtered);
  };

  const handleSelectMember = (member) => {
    setSelectedMember(member);
  }

  return(<>
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center pt-4">
        <div className="flex-1">
        <div className="flex items-center gap-2 text-green-800">
            <BuildingStorefrontIcon className="h-8"/>
            <h1 className="font-semibold leading-6 text-gray-900">Dispensario</h1>
          </div>
          <p className="mt-2 text-sm text-gray-700">
            Selecciona un socio o pasa la tarjeta RFID para realizar una dispensación.
          </p>
        </div>
      </div>
      <div className='pt-6'>   
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Buscar...</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input onChange={handleFilterMembers} defaultValue={''} type="search" 
            className="w-full p-3 pl-10 text-sm text-gray-900 border border-gray-500 rounded-lg focus:ring-green-500 focus:border-green-500" placeholder="Buscar..."/>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0">
                    Nombre
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Nº Socio
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Saldo
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Inscripción
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Cuota
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredMembers.map((member) => (
                  <tr key={member.id} className="hover:bg-gray-300 cursor-pointer" onClick={() => handleSelectMember(member)}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          {member.avatar ? (<img className="h-10 w-10 rounded-full" src={`${api.API_URL}/avatar/${member.avatar}`} alt="" />) :( <div>
                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-10 w-10 rounded-full text-gray-400 border border-gray-300 bg-gray-100">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </div>)}
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{member.name} {member.last_name}</div>
                          <div className="text-gray-500">{member.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{member.member_number || 'No Asignado'}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{member.balance || 0}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className={member.payed_inscription ? 'text-green-600' : 'text-red-600'}>
                        {member.payed_inscription ? 'Pagada' : 'No Pagado'}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className={`text-gray-500 
                        ${new Date(member.subscription_expiration).getTime() < Date.now() ? 'text-red-600' : 'text-green-600'}`}>
                        {member.subscription_expiration.replace('T', ' ').replace('Z', '').split('.')[0]}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>)
}