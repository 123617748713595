import MainLayout from "../../layout/MainLayout";
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { BuildingStorefrontIcon, FireIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CSCApi from "../../services/CSCApi";
import { toast } from "react-hot-toast";

const features = [
  {
    name: 'Socios',
    description:
      'Accede al listado de socios, donde podrás ver todo lo relativo a los integrantes de la asociación.',
    href: '/members',
    icon: UserGroupIcon,
  },
  {
    name: 'Dispensario',
    description:
      'Accede al dispensario desde aquí para ofrecer los productos con solo pasar la tarjeta RFID.',
    href: '/dispensary',
    icon: BuildingStorefrontIcon,
  },
  {
    name: 'Productos',
    description:
      'Añade los productos fácil y rápidamente para obtener datos de stock y poder dispensar a los socios.',
    href: '/products',
    icon: FireIcon,
  },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Dashboard(){
  const [info, setInfo] = useState({
    totalMembers: 0,
    membersToday: 0,
    totalDispensationDonations: 0,
    totalBalanceMovements: 0,
    membersYesterday: 0,
    totalDispensationDonationsYesterday: 0,
    totalBalanceMovementsYesterday: 0,
    totalDispensations: 0,
    totalDispensationsYesterday: 0,
  });
  const [loading, setLoading] = useState(false);

  const loadInfo = async () => {
    if(loading) return;
    setLoading(true);
    try{
      const info = await CSCApi.getDashboardInfo();
      setInfo(info);
      
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadInfo();
  }, [])
  
  const stats = [
    { 
      name: 'Socios', 
      stat: info.totalMembers,
      previousStat: info.totalMembers - info.membersYesterday,
      change: info.membersToday,
      changeType: info.membersToday > 0 ? 'increase' : 'decrease'
    },
    { 
      name: 'Dispensaciones', 
      stat: info.totalDispensations,
      previousStat: info.totalDispensationsYesterday,
      change: info.totalDispensations - info.totalDispensationsYesterday,
      changeType: ((info.totalDispensations - info.totalDispensationsYesterday) >= 0) ? 'increase' : 'decrease'
    },
    { 
      name: 'Dispensado', 
      stat: info.totalDispensationDonations,
      previousStat: info.totalDispensationDonationsYesterday, 
      change: info.totalDispensationDonationsYesterday > 0 ? `${Math.round((info.totalDispensationDonations - info.totalDispensationDonationsYesterday) / info.totalDispensationDonationsYesterday * 100)}%` : '0%',
      changeType: info.totalDispensationDonationsYesterday > 0 ? (info.totalDispensationDonations - info.totalDispensationDonationsYesterday) > 0 ? 'increase' : 'decrease' : 'increase' }, 
    { 
      name: 'Donaciones', 
      stat: info.totalBalanceMovements, 
      previousStat: info.totalBalanceMovementsYesterday, 
      change: info.totalBalanceMovementsYesterday > 0 ? `${Math.round((info.totalBalanceMovements - info.totalBalanceMovementsYesterday) / info.totalBalanceMovementsYesterday * 100)}%` : '0%',
      changeType: info.totalBalanceMovementsYesterday > 0 ? (info.totalBalanceMovements - info.totalBalanceMovementsYesterday) > 0 ? 'increase' : 'decrease' : 'increase'
    },
  ]
  return(<MainLayout>
    <div className="xl:px-32 px-4">
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 border border-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6 truncate">
            <dt className="text-lg font-bold text-gray-900">{item.name}</dt>
            <dd className="mt-1 pt-4 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-green-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">Ayer: {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>

    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
            Gestiona tu asociación con <span className="font-bold text-green-500 text-5xl pl-1">Cannaware</span>
          </h2>
          <p className="t-6 text-lg leading-8 text-gray-600 pt-4 text-center">
            Crea y edita los socios, ten el total control de los pagos de cuotas y donaciones, organiza el stock y dispensa.
          </p>
        </div>
        <div className="mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none text-center">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col justify-center items-center">
                <dt>
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                </dt>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <Link to={feature.href} className="text-sm font-semibold leading-6 text-green-600 hover:text-green-400">
                      Ir al apartado <span aria-hidden="true">→</span>
                    </Link>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
  </MainLayout>)
}