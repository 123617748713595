import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "./context";
import CSCApi from "./services/CSCApi";

export default function Preloader({children}){
  const {user, setUser} = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkToken = async () => {
      if(!localStorage.getItem('token')) return setLoading(false);
      try{
        const {user} = await CSCApi.token();
        setUser(user);
        
      } catch(e){
        localStorage.removeItem('token');
      }
      setLoading(false);
    }
    checkToken();
  }, [])
  if(loading) return <div className="flex justify-center items-center h-full w-full">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
  </div>
  return children
}