import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../../context";
import CSCApi from "../../services/CSCApi";

export default function Login(){
  const {user, setUser} = useContext(GlobalContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    if(loading) return;
    setLoading(true);
    if(!username) return toast.error('Username is required');
    if(!password) return toast.error('Password is required');
    try{
      const data = await CSCApi.login({username, password});
      toast.success(data.message);
      localStorage.setItem('token', data.token);
      setUser(data.user);
    } catch(e){
      
      toast.error(e);
    }
    setLoading(false);
  }

  const handleEnter = (e) => {
    if(e.key === 'Enter') handleLogin();
  }

  if(user) return <Navigate to="/dashboard" />

  return (
    <>
      <div className="flex min-h-full flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center sm:mx-auto sm:w-full sm:max-w-md">
            <img className="w-96" src="/twocolorslogo.svg" alt="Logo" />
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Nombre de usuario
                </label>
                <div className="mt-2">
                  <input value={username} onChange={e => setUsername(e.target.value)} onKeyDown={handleEnter}
                    type="email"
                    autoComplete="username"
                    required
                    className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Contraseña
                </label>
                <div className="mt-2">
                  <input value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleEnter}
                    type="password"
                    required
                    className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}