import { createContext, useState } from "react";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [dispensaryMode, setDispensaryMode] = useState({
    active: false,
    action: () => {}
  });
  return (
    <GlobalContext.Provider value={{user, setUser, dispensaryMode, setDispensaryMode}}>
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalProvider };