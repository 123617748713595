// eslint-disable-next-line import/no-anonymous-default-export
export default new class CalendarGenerator {
  constructor() {
    this.months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
  }

  getMonthName(month) {
    return this.months[month];
  }

  getMonthDays(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  getMonthStartDay(year, month) {
    return new Date(year, month, 1).getDay();
  }

  getMonthEndDay(year, month) {
    return new Date(year, month + 1, 0).getDay();
  }

  getMonth(year, month) {
    const monthName = this.getMonthName(month);
    const days = this.getMonthDays(year, month);
    const startDay = this.getMonthStartDay(year, month);
    const endDay = this.getMonthEndDay(year, month);

    return {
      monthName,
      days,
      startDay,
      endDay
    };
  }

  getCalendar(year, month) {
    const monthData = this.getMonth(year, month);
    const calendar = [];

    let week = [];
    let day = 1;

    for (let i = 1; i < monthData.startDay ; i++) {
      const daysToCalculate = monthData.startDay - 1;
      const date = new Date(year, month, day);
      date.setDate(date.getDate() - daysToCalculate + i);
      week.push({
        date: date.toISOString().split('T')[0],
        events: [],
      });
    }

    while (day <= monthData.days) {
      day++;
      const date = new Date(year, month, day);
      week.push({
        date: date.toISOString().split('T')[0],
        events: [
          { id: 7, name: 'Cinema with friends', time: '9PM', datetime: '2022-02-04T21:00', href: '#' }
        ],
        isCurrentMonth: true,
        isToday: new Date().toISOString().split('T')[0] === date.toISOString().split('T')[0],
      });

      if (week.length === 7) {
        calendar.push(week);
        week = [];
      }
    }
    const daysToAdd = 7 - monthData.endDay;
    
    for(let i = 0; i < daysToAdd; i++) {
      const date = new Date(year, month, day);
      date.setDate(date.getDate() + (i + 1));
      week.push({
        date: date.toISOString().split('T')[0],
        events: [],
      });
    }

    calendar.push(week);

      
    return calendar.flat();
  }
}()