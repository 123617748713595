import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import MainLayout from "../../layout/MainLayout";
import CSCApi from "../../services/CSCApi";
import { PencilSquareIcon, TrashIcon, FireIcon } from '@heroicons/react/24/outline';
import CreateProduct from "./components/CreateProduct";
import ConfirmModal from "../../components/ConfirmModal";
import EditProduct from "./components/EditProduct";

export default function Products(){
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({
    open: false,
    title: "",
    description: "",
    onConfirm: () => {}
  });
  const [selectedEditProduct, setSelectedEditProduct] = useState({
    open: false,
    product: null
  });

  const loadProducts = async () => {
    if(loading) return;
    setLoading(true);
    try{
      const {products} = await CSCApi.getProducts();
      setProducts(products);
      setFilteredProducts(products);
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadProducts();
  }, [])

  const handleFilterProducts = (e) => {
    const { value } = e.target;
    const filtered = products.filter(product => {
      const name = product.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const email = product.slug.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const searchValue = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return (
        name.includes(searchValue) ||
        email.includes(searchValue)
      );
    });
    setFilteredProducts(filtered);
  };
  
  const handleDeleteProduct = async (product) => {
    try{
      setConfirmInfo({
        open: true,
        title: "Eliminar producto",
        description: `¿Estás seguro que deseas eliminar el producto ${product.name}?`,
        onConfirm: async () => {
          try{
            toast.loading("Eliminando producto...", {id: "deleteProduct"});
            const {message} = await CSCApi.deleteProduct({product_id: product.id});
            toast.success(message, {id: "deleteProduct"});
            loadProducts();
          } catch(e){
            toast.error(e, {id: "deleteProduct"});
          }
        }
      });
    }  catch(e){
      toast.error(e, {id: "deleteProduct"});
    }  
  }

  const handleCloseConfirmModal = () => {
    setConfirmInfo({
      open: false,
      title: "",
      description: "",
      onConfirm: () => {}
    });
  }

  const handleCloseEditProductModal = () => {
    setSelectedEditProduct({
      open: false,
      product: null
    });
  }

  const handleOpenEditProductModal = (product) => {
    setSelectedEditProduct({
      open: true,
      product
    });
  }

  return(<MainLayout>
    <ConfirmModal open={confirmInfo.open} close={handleCloseConfirmModal} title={confirmInfo.title} description={confirmInfo.description} onConfirm={confirmInfo.onConfirm} />
    <CreateProduct open={openCreateModal} setOpen={setOpenCreateModal} reload={loadProducts} />
    {selectedEditProduct.open && <EditProduct open={selectedEditProduct.open} close={handleCloseEditProductModal} reload={loadProducts} product={selectedEditProduct.product} /> }
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="md:flex items-center pt-4 gap-4">
        <div className="flex-1">
          <div className="flex items-center gap-2 text-green-800">
            <FireIcon className="h-8"/>
            <h1 className="font-semibold leading-6 text-gray-900">Productos</h1>
          </div>
          <p className="mt-2 text-sm text-gray-700">
            Aquí podrás ver todos los productos de la asociación, crear nuevos productos y editar los existentes.
          </p>
        </div>
        <div className="mt-6 md:mt-0">
          <button onClick={() => setOpenCreateModal(true)}
            type="button"
            className="rounded-md bg-green-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          >
            Añadir Producto
          </button>
        </div>
      </div>
      <div className='pt-6'>   
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Buscar...</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input defaultValue={''} onChange={handleFilterProducts}
            type="search" className="w-full p-3 pl-10 text-sm text-gray-900 border border-gray-500 rounded-lg focus:ring-green-500 focus:border-green-500" placeholder="Buscar..." />
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0">
                    Producto
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Categoría
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Coste
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Cantidad
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredProducts.map((product) => (
                  <tr key={product.id} className='hover:bg-gray-300'>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="capitalize">
                          <div className="font-medium text-gray-900">{product.name}</div>
                          <div className="text-gray-500">{product.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{product.category}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{product.cost}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{product.stock}</div>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-md font-medium sm:pr-0 flex justify-end gap-4">
                      <button onClick={() => handleOpenEditProductModal(product)} 
                        className="text-blue-500 hover:text-blue-700">
                        <PencilSquareIcon className="h-8"/><span className="sr-only">, {product.name}</span>
                      </button>
                      <button onClick={() => handleDeleteProduct(product)}
                      className="text-red-500 hover:text-red-700">
                        <TrashIcon className="h-8"/><span className="sr-only">, {product.name}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>)
}