import { Fragment, useEffect, useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import MainLayout from '../../../layout/MainLayout'
import CalendarGenerator from '../../../services/CalendarGenerator'
import CSCApi from '../../../services/CSCApi'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import CreateEventModal from './CreateEventModal'
import ConfirmModal from '../../../components/ConfirmModal'
import { toast } from 'react-hot-toast'




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const monthsList = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

export default function Calendar() {
  const [actualMonth, setActualMonth] = useState(new Date().getMonth());
  const [actualYear, setActualYear] = useState(new Date().getFullYear());
  const [openCreateEventModal, setOpenCreateEventModal] = useState({
    isOpen: false,
    day: null
  });
  const [events, setEvents] = useState([]);
  const [openDeleteEventModal, setOpenDeleteEventModal] = useState({
    open: false,
    title: '', 
    description: '',
    onConfirm: () => {}
  });
  const [selectedDay, setSelectedDay] = useState('');

  const days = CalendarGenerator.getCalendar(actualYear, actualMonth)


  const handleNextMonth = () => {
    if (actualMonth === 11) {
      setActualMonth(0);
      setActualYear(actualYear + 1);
    } else {
      setActualMonth(actualMonth + 1);
    }
  };

  const handlePreviousMonth = () => {
    if (actualMonth === 0) {
      setActualMonth(11);
      setActualYear(actualYear - 1);
    } else {
      setActualMonth(actualMonth - 1);
    }
  };

  const loadCalendar = async () => {
    try{
      const days = CalendarGenerator.getCalendar(actualYear, actualMonth)
      const actualMonthDays = days.filter((day) => day.isCurrentMonth).map((day) => day.date)
      const calendarInfo = await CSCApi.getCalendar({days: actualMonthDays})
      setEvents(calendarInfo.calendar)
    } catch(e){ 
      console.error(e)
    }

  };

  useEffect(() => {
    loadCalendar();
  }, [actualMonth, actualYear]);

  const handleCreateModalEvent = (day) => {
    setOpenCreateEventModal({isOpen: true, day})
  }

  const handleCloseCreateEventModal = () => {
    setOpenCreateEventModal({isOpen: false, day: null})
  }

  const handleDeleteEvent = async (id) => {
    setOpenDeleteEventModal({
      open: true,
      title: 'Estas seguro de eliminar este evento?',
      description: 'Esta acción no se puede deshacer',
      onConfirm: async () => {
        toast.loading('Eliminando evento...', {id: 'delete-event-success'})
        try{
          const {message} =await CSCApi.deleteCalendarEvent({id})
          toast.success(message, {id: 'delete-event-success'})
          loadCalendar()
          handleCloseDeleteModal()
        } catch(e){
          toast.error('Error al eliminar el evento', {id: 'delete-event-error'})
        }
      }
    })
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteEventModal({open: false, title: '', description: '', onConfirm: () => {}})
  }

  return (<MainLayout>
    <ConfirmModal open={openDeleteEventModal.open} title={openDeleteEventModal.title} description={openDeleteEventModal.description} onConfirm={openDeleteEventModal.onConfirm} close={handleCloseDeleteModal} />
    <CreateEventModal open={openCreateEventModal.isOpen} day={openCreateEventModal.day} close={handleCloseCreateEventModal} reload={loadCalendar} />
    <div className="lg:flex lg:h-full lg:flex-col px-4">
      <header className="flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          {monthsList[actualMonth]} {actualYear}
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <div
              className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300 "
              aria-hidden="true"
            />
            <button onClick={handlePreviousMonth}
              type="button"className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button type="button" className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block w-24">
              {monthsList[actualMonth]}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button onClick={handleNextMonth}
              type="button"className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {days.map((day) => (
              <div
                key={day.date}
                className={classNames(
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                  'relative py-2 px-3'
                )}
              >
                <div className='flex justify-between items-center'>
                  <div
                    className={
                      day.isToday
                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-green-600 font-semibold text-white'
                        : undefined
                    }
                  >
                    <div>{day.date.split('-').pop().replace(/^0/, '')}</div>
                  </div>
                  {day.isCurrentMonth && <button onClick={() => handleCreateModalEvent(day.date)}
                    >
                    <PlusCircleIcon  className='h-4 text-green-600'/>
                  </button>}
                </div>
                {events.filter((e) => e.date === day.date).length > 0 && (
                  <ol className="mt-2">
                    {events.filter((e) => e.date === day.date).slice(0, 3).map((event) => (
                      <li key={event.id}>
                        <a href={event.href} className="group flex">
                          <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-green-600">
                            {event.name}
                          </p>
                          <div className="ml-3 flex-none text-gray-500 group-hover:text-green-600 flex items-center">
                            <p className='hidden xl:flex'>{event.type}</p>
                            <button onClick={() => handleDeleteEvent(event.id)}>
                              <TrashIcon className='h-4 text-red-600'/>
                            </button>
                          </div>
                        </a>
                      </li>
                    ))}
                    {events.filter((e) => e.date === day.date).length > 3 && <li className="text-gray-500">+ {events.filter((e) => e.date === day.date).length - 2} mas</li>}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                className={classNames(
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isToday && 'text-green-600',
                  !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                  'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                )}
                onClick={() => setSelectedDay(day.date)}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                    day.isSelected && day.isToday && 'bg-green-600',
                    day.isSelected && !day.isToday && 'bg-gray-900',
                    'ml-auto'
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
                <span className="sr-only">{events.filter((e) => e.date === day.date).length} events</span>
                {events.filter((e) => e.date === day.date).length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {events.filter((e) => e.date === day.date).map((event) => (
                      <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-green-400" />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {selectedDay && (
        <div className="py-10 px-4 sm:px-6 lg:hidden">
          <div className='flex justify-end my-3'>
            <button onClick={() => handleCreateModalEvent(selectedDay)}
              className="ml-6 rounded-md  bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              Añadir evento
            </button>
          </div>
          {events.filter((e) => e.date === selectedDay).length > 0 && (
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {events.filter((e) => e.date === selectedDay).map((event) => (
              <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                <div className="flex-auto w-full">
                  <p className="font-semibold text-gray-900">{event.name}</p>
                  <div className="mt-2 flex items-center text-gray-700">
                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {event.description}
                  </div>
                </div>
                <button onClick={() => handleDeleteEvent(event.id)}>
                  <TrashIcon className='h-4 text-red-600'/>
                </button>
              </li>
            ))}
          </ol>)}
        </div>
      )}
    </div>
  </MainLayout>)
}