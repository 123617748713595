import { useContext, useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import MemberSelect from "./components/MemberSelect";
import POS from "./components/POS";

export default function Dispensary(){
  const [selectedMember, setSelectedMember] = useState(null);
  
  return (<MainLayout>
    {selectedMember ? <POS memberID={selectedMember.id} setSelectedMember={setSelectedMember} /> : <MemberSelect setSelectedMember={setSelectedMember} />}
  </MainLayout>)
}