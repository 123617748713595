import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../context";

export default function UserRoute({children}){
  const {user} = useContext(GlobalContext);
  
  useEffect(() => {
    if(!user){
      window.location.href = '/';
    }
  }, [user])

  return user ? children : null;
}