import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import api from "../../config/api";
import MainLayout from "../../layout/MainLayout";
import CSCApi from "../../services/CSCApi";
import CreateMemberModal from "./components/CreateMemberModal";
import { PencilSquareIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import ConfirmModal from "../../components/ConfirmModal";
import BalanceModal from "./components/BalanceModal";
import SubscriptionModal from "./components/SubscriptionModal";
import EditMemberModal from "./components/EditMemberModal";

export default function Members(){
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openBalanceModal, setOpenBalanceModal] = useState({
    open: false,
    member: null
  });
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState({
    open: false,
    member: null
  });
  const [openEditModal, setOpenEditModal] = useState({
    open: false,
    member: null
  });
  const [search, setSearch] = useState("");
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {}
  });

  const loadMembers = async () => {
    if(loading) return;
    setLoading(true);
    try{
      const {members} = await CSCApi.getMembers();
      setMembers(members);
      setFilteredMembers(members);
    } catch(e){
      toast.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadMembers();
  }, [])

  const handleFilterMembers = (e) => {
    const { value } = e.target;
    const filtered = members.filter(member => {
      const name = member.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const lastname = member.last_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const nameAndLastname = `${name} ${lastname}`;
      const email = member.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const searchValue = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return (
        name.includes(searchValue) ||
        email.includes(searchValue) ||
        lastname.includes(searchValue) ||
        nameAndLastname.includes(searchValue) ||
        (member.member_number === Number(value)) ||
        member.rfid === value
      );
    });
    setSearch(value);
    setFilteredMembers(filtered);
  };

  const handleConfirmPayInscription = (member) => {
    if(member.payed_inscription) return toast.error("La inscripción ya fue pagada", {id: "inscription_error"});
    if(member.balance < 5) return toast.error("El socio no tiene saldo suficiente para pagar la inscripción", {id: "inscription_error"});
    setConfirmation({
      open: true,
      title: "Confirmar pago de inscripción",
      description: `¿Estás seguro de que quieres confirmar el pago de la inscripción de ${member.name}?`,
      onConfirm: async () => {
        try{
          const {message} = await CSCApi.payInscription({member_id: member.id});
          toast.success(message);
          handleReload();
        } catch(e){
          toast.error(e);
        }
      }
    });
  }

  const handlePaySubscription = (member) => {
    setOpenSubscriptionModal({
      open: true,
      member
    });
  }

  const handleEditBalance = (member) => {
    setOpenBalanceModal({
      open: true,
      member
    });
  }

  const handleEditMember = (member) => {
    setOpenEditModal({
      open: true,
      member
    });
  }
  
  const handleReload = () => {
    loadMembers();
    setSearch("");
  }

  return(<MainLayout>
    <ConfirmModal open={confirmation.open} close={() => setConfirmation({...confirmation, open: false})}
      title={confirmation.title} description={confirmation.description} onConfirm={confirmation.onConfirm} />
    <CreateMemberModal open={openCreateModal} setOpen={setOpenCreateModal} reload={handleReload} members={members} />
    {openBalanceModal.member && <BalanceModal open={openBalanceModal.open} close={() => setOpenBalanceModal({...openBalanceModal, open: false})} member={openBalanceModal.member} reload={handleReload} />}
    {openSubscriptionModal.member && <SubscriptionModal open={openSubscriptionModal.open} close={() => setOpenSubscriptionModal({...openSubscriptionModal, open: false})} member={openSubscriptionModal.member} reload={handleReload} />}
    {openEditModal.member && <EditMemberModal open={openEditModal.open} close={() => setOpenEditModal({...openEditModal, open: false})} member={openEditModal.member} reload={handleReload} members={members} />}
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center pt-4">
        <div className="flex-1">
          <div className="flex items-center gap-2 text-green-800">
            <UserGroupIcon className="h-8"/>
            <h1 className="font-semibold leading-6 text-gray-900">Socios</h1>
          </div>
          <p className="mt-2 text-sm text-gray-700">
            Aquí puedes ver todos los socios de la asociación, crear nuevos socios y editar los existentes.
          </p>
        </div>
        <div>
          <button onClick={() => setOpenCreateModal(true)}
            type="button"
            className="rounded-md bg-green-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          >
            Crear Socio
          </button>
        </div>
      </div>
      <div className='pt-6'>   
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Buscar...</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input onChange={handleFilterMembers} value={search}
            type="search" className="w-full p-3 pl-10 text-sm text-gray-900 border border-gray-500 rounded-lg focus:ring-green-500 focus:border-green-500" placeholder="Buscar..."/>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Nº Socio
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Inscripción (5 CSC)
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900 hidden xl:block">
                    Teléfono
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Cuota
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                    Saldo
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-bold text-gray-900">
                  <span className="sr-only">Saldo</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredMembers.map((member) => (
                  <tr key={member.id} className=''>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          {member.avatar ? (<img className="h-10 w-10 rounded-full" src={`${api.API_URL}/avatar/${member.avatar}`} alt="" />) :( <div>
                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-10 w-10 rounded-full text-gray-400 border border-gray-300 bg-gray-100">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </div>)}
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{member.name} {member.last_name}</div>
                          <div className="text-gray-500">{member.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">{member.member_number || 'No Asignado'}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className={member.payed_inscription ? 'text-green-600 font-bold' : 'text-yellow-700'}>
                        {member.payed_inscription ? 'Pagada' : 'No Pagado'}
                      </div>
                      {member.payed_inscription || <button onClick={() => handleConfirmPayInscription(member)}
                      className="font-bold text-red-600 hover:text-red-700">
                        Pagar<span className="sr-only">, {member.name}</span>
                      </button>}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden xl:block">
                      <div className="text-gray-500">{member.phone}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-500">
                        {member.subscription_expiration.split('T')[0]} - {member.subscription_expiration.split('T')[1].split('.')[0]}
                      </div>
                      <button onClick={() => handlePaySubscription(member)}
                      className="font-bold text-green-600 hover:text-green-700">
                        Añadir<span className="sr-only">, {member.name}</span>
                      </button>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm sm:pr-0">
                    <div className="text-gray-500 font-medium">{member.balance}</div>
                      <button onClick={() => handleEditBalance(member)}
                      className="font-bold text-green-600 hover:text-green-700">
                        Añadir<span className="sr-only">, {member.name}</span>
                      </button>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-md font-medium sm:pr-0">
                      <button className="text-blue-500 hover:text-blue-700" onClick={() => handleEditMember(member)}>
                        <PencilSquareIcon className="h-6"/><span className="sr-only">, {member.name}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </div>
  </MainLayout>)
}