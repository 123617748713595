import { Routes, Route } from 'react-router-dom';
import UserRoute from '../components/UserRoute';
import Administration from './administration';
import Calendar from './administration/components/Calendar';
import DispensaryHistory from './administration/components/DispensaryHistory';
import DispensationsDay from './administration/components/DispensationsDay';
import DonationHistory from './administration/components/DonationHistory';
import Expenses from './administration/components/Expenses';
import MostActiveMembers from './administration/components/MostActiveMembers';
import MostlyDispensedMembers from './administration/components/MostlyDispensedMembers';
import MostlyDispensedProducts from './administration/components/MostlyDispensedProducts';
import StockMovements from './administration/components/StockMovements';
import SubscriptionHistory from './administration/components/SubscriptionHistory';
import Dashboard from './dashboard/Dashboard';
import Dispensary from './dispensary';
import Login from './login';
import Logout from './Logout';
import Members from './members';
import Products from './products';

export default function Pages(){
  return(<Routes>
    <Route path="/" element={<Login />} />
    <Route path="/logout" element={<UserRoute><Logout /></UserRoute>} />
    <Route path="/dashboard" element={<UserRoute><Dashboard /></UserRoute>} />
    <Route path="/members" element={<UserRoute><Members /></UserRoute>} />
    <Route path="/dispensary" element={<UserRoute><Dispensary /></UserRoute>} />
    <Route path="/products" element={<UserRoute ><Products /></UserRoute>} />
    <Route path="/administration" element={<UserRoute><Administration /></UserRoute>} />
    <Route path="/administration/dispensaryHistory" element={<UserRoute><DispensaryHistory /></UserRoute>} />
    <Route path="/administration/donationHistory" element={<UserRoute><DonationHistory /></UserRoute>} />
    <Route path="/administration/mostlyDispensedMembers" element={<UserRoute><MostlyDispensedMembers /></UserRoute>} />
    <Route path="/administration/mostActiveMembers" element={<UserRoute><MostActiveMembers /></UserRoute>} />
    <Route path="/administration/mostlyDispensedProducts" element={<UserRoute><MostlyDispensedProducts /></UserRoute>} />
    <Route path="/administration/subscriptionHistory" element={<UserRoute><SubscriptionHistory /></UserRoute>} />
    <Route path="/administration/stockMovements" element={<UserRoute><StockMovements /></UserRoute>} />
    <Route path="/administration/calendar" element={<UserRoute><Calendar /></UserRoute>} />
    <Route path="/administration/dispensationsDay" element={<UserRoute><DispensationsDay /></UserRoute>} />
    <Route path="/administration/expenses" element={<UserRoute><Expenses /></UserRoute>} />
  </Routes>)
}