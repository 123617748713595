import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../context";

export default function Logout(){
  const {setUser} = useContext(GlobalContext)

  useEffect(() => {
    localStorage.removeItem('token')
    setUser(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Navigate to="/" />
}