import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-hot-toast';
import CSCApi from '../../../services/CSCApi';

const emptyState = {
  name: '',
  slug: '',
  category: '',
  stock: '',
  cost: '',
  active: true,
}

export default function CreateProduct({open, setOpen, reload}) {
  const [loading, setLoading] = useState(false);
  const [productInfo, setProductInfo] = useState(emptyState);
  
  const handleCreateProduct = async () => {
    if(loading) return;
    if(!productInfo.name) return toast.error('Debes introducir un nombre para el producto');
    if(!productInfo.slug) return toast.error('Debes introducir un slug para el producto');
    if(!productInfo.category) return toast.error('Debes introducir una categoría para el producto');
    if(isNaN(productInfo.stock)) return toast.error('Debes introducir un stock para el producto');
    if(isNaN(productInfo.cost)) return toast.error('Debes introducir un coste para el producto');
    setLoading(true);
    try{
      toast.loading('Creando producto...', {id: 'createProduct'});
      await CSCApi.createProduct(productInfo);
      toast.success('Producto creado correctamente', {id: 'createProduct'});
      setOpen(false);
      reload();
    } catch(e){
      toast.error(e, {id: 'createProduct'});
    }
    setLoading(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex w-full sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base pb-2 font-semibold leading-6 text-gray-900">
                      Crear nuevo producto
                    </Dialog.Title>
                    <div className='grid md:grid-cols-2 gap-x-6 gap-y-4'>
                      <input value={productInfo.name} onChange={(e) => setProductInfo({...productInfo, name: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Nombre" />
                      
                      <input value={productInfo.slug} onChange={(e) => setProductInfo({...productInfo, slug: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Slug" />

                      <input value={productInfo.stock} onChange={(e) => setProductInfo({...productInfo, stock: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Stock" />

                      <input value={productInfo.cost} onChange={(e) => setProductInfo({...productInfo, cost: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Coste" />

                      <select value={productInfo.category} onChange={(e) => setProductInfo({...productInfo, category: e.target.value})}
                      className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" >
                        <option value="">Categoría</option>
                        <option value="Flores">Flores</option>
                        <option value="Dry Sift">Dry Sift</option>
                        <option value="Extractos">Extractos</option>
                        <option value="CBD">CBD</option>
                        <option value="Otros">Otros</option>
                      </select>
                      <div className="relative flex items-start pt-4 justify-center">
                        <div className="flex h-6 items-center">
                          <input checked={productInfo.active} onChange={(e) => setProductInfo({...productInfo, active: !productInfo.active})}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="offers" className="font-medium text-gray-900">
                            Activo?
                          </label>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center pt-8 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => handleCreateProduct()}
                  >
                    Crear
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}