import axios from 'axios';
import api from '../config/api';

// eslint-disable-next-line import/no-anonymous-default-export
export default new class CSCApi{
  constructor(){
    this.API_URL = api.API_URL;
    this.headers = () => ({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
  login({username, password}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/auth/login`, {username, password});
        resolve(data);
      } catch(e){
        console.log(e)
        reject(e.response.data.message)
      }
    });
  }
  token(){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.get(`${this.API_URL}/auth/token`, {headers: this.headers()});
        resolve(data);
      } catch(e){
        console.log(e)
        reject(e.response.data.message)
      }
    });
  }
  createMember({name, last_name, email, address, phone, dni, avatar, guarantor_id, rfid, guarantor_contract, consumption_contract, scanned_dni, payed_inscription, monthly_consumption}){
    return new Promise(async (resolve, reject) => {
      try{
        const sendData = {name, last_name, email, address, phone, dni, guarantor_id: guarantor_id.id, rfid, guarantor_contract, consumption_contract, scanned_dni, payed_inscription, monthly_consumption,
          avatar, 
          }
        const {data} = await axios.post(`${this.API_URL}/member/create`, sendData, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getMembers(){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.get(`${this.API_URL}/member/members`, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getMember(member_id){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/member/member`, {member_id}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  createProduct({name,slug,cost,stock,category,active}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/products/create`, {name,slug,cost,stock,category,active}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getProducts(){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.get(`${this.API_URL}/products/`, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  addBalance({member_id, amount}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/member/addBalance`, {member_id, amount}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  payInscription({member_id}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/member/payInscription`, {member_id}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  paySubscription({member_id, amount}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/member/paySubscription`, {member_id, amount}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  editMember({name, last_name, email, member_number, address, phone, dni, avatar, guarantor_id, rfid, guarantor_contract, consumption_contract, scanned_dni, payed_inscription, monthly_consumption, member_id}){
    return new Promise(async (resolve, reject) => {
      try{
        const sendData = {name, last_name, member_number, email, address, avatar, phone, dni, guarantor_id: guarantor_id.id, rfid, guarantor_contract, consumption_contract, scanned_dni, payed_inscription, monthly_consumption, member_id}
        const {data} = await axios.post(`${this.API_URL}/member/edit`, sendData, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  deleteProduct({product_id}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/products/delete`, {product_id}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  editProduct({name, slug, category, stock, cost, active, product_id}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/products/edit`, {name, slug, category, stock, cost, active, product_id}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  createDispensation({member_id, products}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/dispensary/`, {member_id, products}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getDashboardInfo(){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.get(`${this.API_URL}/info/`, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getDonationHistory({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/donationHistory`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getDispensaryHistory({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/dispensaryHistory`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getSubscriptionHistory({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/subscriptionHistory`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getMostlyDispensedProducts({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/mostlyDispensedProducts`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getMostlyDispensedMembers({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/mostlyDispensedMembers`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getMostlyActiveMembers({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/mostlyActiveMembers`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getStockMovements({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/stockMovements`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getDispensationsDay({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/info/dispensationsDay`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getCalendar({days}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/calendar/`, {days}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  createCalendarEvent({name, date, description, type}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/calendar/add`, {name, date, description, type}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  deleteCalendarEvent({id}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/calendar/remove`, {id: id}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  changePassword({password, new_password}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/user/changePassword`, {password, new_password}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  getExpenses({date_from, date_to}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/expenses/`, {date_from, date_to}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  createExpense({name, description, total_payed, total_amount}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/expenses/add`, {name, description, total_payed, total_amount}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  addPaymentExpense({expense_id, amount}){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/expenses/addPayment`, {expense_id, amount}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
  deleteExpense(expense_id){
    return new Promise(async (resolve, reject) => {
      try{
        const {data} = await axios.post(`${this.API_URL}/expenses/delete`, {expense_id}, {headers: this.headers()});
        resolve(data);
      } catch(e){
        reject(e.response.data.message)
      }
    });
  }
}()