import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Fragment, useContext, useState } from "react";
import userMenu from "../config/userMenu";
import { Link } from "react-router-dom";
import { GlobalContext } from "../context";
import ChangePasswordModal from "./ChangePasswordModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Topbar({setMobileMenuOpen}){
  const {dispensaryMode, user} = useContext(GlobalContext);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  return(<header className="w-full">
  <ChangePasswordModal open={changePasswordOpen} close={() => setChangePasswordOpen(false)} reload={() => window.location.reload()} />
  <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
    <button
      type="button"
      className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 md:hidden"
      onClick={() => setMobileMenuOpen(true)}
    >
        <span className="sr-only">Open sidebar</span>
        <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className={`flex flex-1 ${dispensaryMode.active ? 'justify-between ' : 'justify-end'} px-4 sm:px-6`}>
        {dispensaryMode.active && (<div className="flex items-center">
          <button type="button" onClick={() => dispensaryMode.action()} >
            <ArrowLeftIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>)}
        <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
          {/* Profile dropdown */}
          <Menu as="div" className="relative flex-shrink-0">
            <div>
              <Menu.Button className="flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
              <p className="mr-3 first-letter:capitalize font-bold text-green-900">{user.username}</p>
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full"
                  src={`https://api.dicebear.com/5.x/bottts-neutral/svg?radius=0&seed=${user.id}${user.username}&backgroundType=gradientLinear`}
                  alt=""
                />
                
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <button onClick={() => setChangePasswordOpen(true)}
                  className={'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-start'}>
                    Cambiar Contraseña
                  </button>
                </Menu.Item>
                {userMenu.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  </header>);
}